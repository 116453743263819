[
  "To the sandy beach of sea-coast Aulis I came after a voyage through the tides of Euripus, leaving Chalcis on its narrow firth, my city which feedeth the waters of far-famed Arethusa near the sea, that I might behold the army of the Achaeans and the ships rowed by those god-like heroes.",
  "For our husbands tell us that fair-haired Menelaus and high-born Agamemnon are leading them to Troy on a thousand ships in quest of the lady Helen, whom herdsman Paris carried off from the banks of reedy Eurotas-his guerdon from Aphrodite, when that queen of Cyprus entered beauty's lists with Hera and Pallas at the gushing fount.",
  "Through the grove of-Artemis, rich with sacrifice, I sped my course, the red blush mantling on my cheeks from maiden modesty, in my eagerness to see the soldiers' camp, the tents of the mail-clad Danai, and their gathered steeds.",
  "Two chieftains there I saw met together in council; one was Aias, son of Oileus; the other Aias, son of Telamon, crown of glory to the men of Salamis; and I saw Protesilaus and Palamedes, sprung from the son of Poseidon, sitting there amusing themselves with intricate figures at draughts.",
  "Diomedes too at his favourite sport of hurling quoits; and Meriones, the War-god's son, a marvel to mankind, stood at his side; likewise I beheld the offspring of Laertes, who came from his island hills, and with him Nireus, handsomest of all Achaeans.",
  "Achilles next, that nimble runner, swift on his feet as the wind, whom Thetis bore and Chiron trained; him I saw upon the beach, racing in full armour along the shingle and straining every nerve to beat a team of four horses, as he sped round the track on foot.",
  "And Eumelus, the grandson of Pheres, their driver, was shouting when I saw him. goading on his goodly steeds, with their bits of chased goldwork.",
  "Whereof the centre pair, that bore the yoke, had dappled coats picked out with white, while the trace-horses, on the outside, facing the turning-post in the course, were bays with spotted fetlocks.",
  "Close beside them Peleus' son leapt on his way, in all his harness, keeping abreast the rail by the axle-box.",
  "Next I sought the countless fleet, a wonder to behold, that I might fill my girlish eyes with gazing, a sweet delight.",
  "The warlike Myrmidons from Phthia held the right wing with fifty swift cruisers, upon whose sterns, right at the ends, stood Nereid goddesses in golden-effigy, the ensign of Achilles' armament.",
  "Near these were moored the Argive ships in equal numbers, o'er which Mecisteus' son, whom Taulaus his grandsire reared, and Sthenelus, son of Capaneus, were in command.",
  "Next in order, Theseus' son was stationed at the head of sixty ships from Attica, having the goddess Pallas set in a winged car drawn by steeds with solid hoof, a lucky sight for mariners.",
  "Then I saw Boeotia's fleet of fifty sails decked with ensigns; these had Cadmus at the stern holding a golden dragon at the beaks of the vessels, and earth-born Leitus was their admiral. ",
  "Likewise there were ships from Phocis; and from Locris came the son of Oileus with an equal contingent, leaving famed Thronium's citadel.",
  "And from Mycenae, the Cyclopes' town, Atreus' son sent a hundred wellmanned galleys",
  "His brother being with him in command, as friend with friend, that Hellas might exact on her, who had fled her home to wed a foreigner.",
  "Also I saw upon Gerenian Nestor's prows twelve from Pylos the sign of his neighbor Alpheus, four-footed like a bull.",
  "Moreover there was a squadron of Aenianian sail under King and next the lords of Elis, stationed near'-them, whom all the people named Epeians",
  "And Eurytus was lord of these; likewise he led the Taphian warriors with the white oar-blades",
  "The subjects of Meges, son of Phyleus, who had left the isles of the Echinades, where sailors cannot land.",
  "Lastly, Aias, reared in Salamis, was joining his right wing to the left of those near whom he was posted",
  "Closing the line with his outermost ships-twelve barques obedient to the helm-as I heard and then saw the crews",
  "No safe return shall he obtain, who bringeth his barbaric boats to grapple Aias.",
  "There I saw the naval armament, but some things I heard at home about the gathered host, whereof I still have a recollection.",
  "Aye, but a mind unstable is an unjust possession, disloyal to friends. Now I am anxious to test thee, and seek not thou from rage to turn aside from the truth, nor will I on my part overstrain the case. Thou rememberest when thou wert all eagerness to captain the Danai against Troy, making a pretence of declining, though eager for it in thy heart; how humble thou wert then!",
  "Taking each man by the hand and keeping open doors for every fellow townsman who cared to enter, affording each in turn a chance to speak with thee, even though some desired it not, seeking by these methods to purchase popularity from all bidders; then when thou hadst secured the command, there came a change over thy manners; thou wert no longer so cordial before to whilom friends, but hard of access, seldom to be found at home.",
  "But the man of real worth ought not to change his manners in the hour of prosperity, but should then show himself most staunch to friends, when his own good fortune can help them most effectually.",
  "This was the first cause I had to reprove thee, for it was here I first discovered thy villainy; but afterwards, when thou camest to Aulis with all the gathered hosts of Hellas, thou wert of no account; no! the want of a favourable breeze filled thee with consternation at the chance dealt out by Heaven.",
  "Anon the Danai began demanding that thou shouldst send the fleet away instead of vainly toiling on at Aulis; what dismay and confusion was then depicted in thy looks, to think that thou, with a thousand ships at thy command, hadst not occupied the plains of Priam with thy armies!",
  "And thou wouldst ask my counsel, “What am I to do? what scheme can I devise. where find one?” to save thyself being stripped of thy command and losing thy fair fame. Next when Calchas bade thee offer thy daughter in sacrifice to Artemis.",
  "Declaring that the Danai should then sail, thou wert overjoyed, and didst gladly undertake to offer the maid, and of thine own accord-never allege compulsion!-thou art sending word to thy wife to despatch thy daughter hither on pretence of wedding Achilles.",
  "This is the same air that heard thee say it; and after all thou turnest round and hast been caught recasting thy letter to this effect, “I will no longer be my daughter's murderer.”",
  "Exactly so! Countless others have gone through this phase in their conduct of public affairs; they make an effort while in power, and then retire dishonourably, sometimes owing to the senselessness of the citizens, sometimes deservedly, because they are too feeble of themselves to maintain their watch upon the state.",
  "For my part, I am more sorry for our unhappy Hellas, whose purpose was to read these worthless foreigners a lesson, while now she will let them escape and mock her, thanks to thee and thy daughter.",
  "May I never then appoint a man to rule my country or lead its warriors because his kinship! Ability what the general must have; since any man, with ordinary intelligence, can govern a state.",
  "For brethren to come to words and blows, whene'er they disagree, is terrible.",
  "I wish to rebuke thee in turn, briefly, not lifting mine eyes too high in shameless wise, but in more sober fashion, as a brother; for it is a good man's way to be considerate. Prithee, why this burst of fury, these bloodshot eyes? who wrongs thee? what is it thou wantest?",
  "Thou art fain to win a virtuous bride. Well, I cannot supply thee; for she, whom thou once hadst, was ill controlled by thee. Am I then, a man who never went astray, to suffer for thy sins? or is it my popularity that galls thee? No! it is the longing thou hast to keep a fair wife in thy embrace, casting reason and honour to the winds.",
  "A bad man's pleasures are like himself Am I mad, if I change to wiser counsels, after previously deciding amiss? Thine is the madness rather in wishing to recover a wicked wife, once thou hadst lost her-a stroke of Heaven-sent luck.",
  "Those foolish suitors swore that oath to Tyndareus in their longing to wed; but Hope was the goddess that led them on, I trow, and she it was that brought it about rather then thou and thy mightiness. So take the field with them; they are ready for it in the folly of their hearts; for the deity is not without insight, but is able to discern where oaths have been wrongly pledged or forcibly extorted.",
  "I will not slay my children, nor shall thy interests be prospered by justice in thy vengeance for a worthless wife, while I am left wasting, night and day, in sorrow for what I did to one of my own flesh and blood, contrary to all law and justice.",
  "There is thy answer shortly' given, clear and easy to understand; and if thou wilt not come to thy senses, I shall do the best for myself.",
  "Agamemnon, lord of all Hellenes! I am come and bring thee thy daughter, whom thou didst call Iphigenia in thy home; and her mother, thy wife Clytemnestra, is with her, and the child Orestes, a sight to gladden thee after thy long absence from thy palace; but, as they had been travelling long and far, they are now refreshing their tender feet at the waters of a fair spring, they and their horses",
  "Come, then, begin the rites-that is the next step-by getting the baskets ready; crown your heads; prepare the wedding-hymn, thou and prince Menelaus with thee; let flutes resound throughout the tents with noise of dancer's feet; for this is a happy day, that is come for the maid.",
  "But I am come as their forerunner to prepare thee for their reception; for the army knows already of thy daughter's arrival, so quickly did the rumour spread; and all the folk are running together to the sight, that they may see thy child; for Fortune's favourites enjoy a worldwide fame and have all eyes fixed on them.",
  " “Is it a wedding?” some ask, “or what is happening? or has king Agamemnon from fond yearning summoned his daughter hither?” From others thou wouldst have heard: “They are presenting the maiden to Artemis, queen of Aulis, previous to marriage; who can the bridegroom be, that is to lead her home?”"
]
